<template>
<v-col cols="12" md="4" lg="4" justify="center">
    <div @click="launchComplaintModal()">
        <slot name="activator"></slot>
    </div>
    <!-- <slot name="removeItemComplaint"></slot> -->
    <v-dialog v-model="showAddComplaintModal" :persistent="disableClickOutside" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">Complaint</span>
                <!-- <v-spacer></v-spacer>
                <v-btn icon @click="cancelComplaint()">
                    <v-icon>mdi-close</v-icon>
                </v-btn> -->
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" class="py-0">
                            <v-select
                            :items="complaintTopic"
                            label="Topic"
                            v-model="item.topic"
                            dense
                            :disabled="fieldDisable"
                            outlined
                        ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="py-0">
                            <v-textarea outlined label="รายละเอียด" :disabled="fieldDisable" v-model="item.content" no-resize rows="3" class="pa-0 ma-0"></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-row justify="center" no-gutters>
                    <v-btn v-if="!fieldDisable" color="red" @click="removeComplaint(item.tmp_id)" text>Remove</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text @click="cancelComplaint()">{{ cancelLabel }}</v-btn>
                    <v-btn v-if="!fieldDisable" text color="blue darken-1" @click="submitComplaint()">Save</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-col>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "AddComplaintModal",
    props: ['complaint','fieldDisable'],
    data:() => ({
        showAddComplaintModal: false,
        disableClickOutside: true,
        complaintTopic: [
            'ล้างรถ', 
            'SA',
            "ความสุภาพ มารยาท",
            "ความกระตือรือล้น",
            "ความครบถ้วน อธิบายหลังการซ่อม/สอบถาม",
            "แจ้งความคืบหน้าการซ่อมของรถลูกค้า",
            "การประสานงานพนักงานรับรถ ส่วนงานที่เกี่ยวข้อง",
            "รูปไม่ครบ / รูปไม่ชัด", 
            'ช่าง', 
            'อะไหล่', 
            'อื่นๆ'
        ],
        item: {
            tmp_id: null,
            topic: null,
            content: null
        },
        clearItem: {
            tmp_id: null,
            topic: null,
            content: null
        },
        cancelLabel: null
    }),
    created() {
        this.item = { ...this.complaint }
        this.clearItem = { ...this.complaint }
    },
    methods: {
        ...mapActions({
            updateComplaint: 'job/UPDATE_COMPLAINT',
            removeComplaint: 'job/REMOVE_COMPLAINT'
        }),
        submitComplaint() {
            this.updateComplaint(this.item)
            this.clearItem = { ...this.item }
            this.showAddComplaintModal = false
        },
        cancelComplaint() {
            this.item = { ...this.clearItem }
            this.updateComplaint(this.item)
            this.showAddComplaintModal = false
        },
        launchComplaintModal() {
            this.showAddComplaintModal = true;
            this.cancelLabel = this.fieldDisable ? "Close" : "Cancel";
        },
    }
};
</script>

<style lang="scss" scoped>
</style>
