<template>
<div>
    <div class="title-header">
        <h1>Create Jobs</h1>
    </div>
    <v-container id="jobsTable" tag="section">
        <v-card class="card-container" elevation="0">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3" class="py-1">
                        <v-text-field label="Job ID" outlined dense v-model="jobForm.jobID"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="py-1">
                        <v-text-field label="Car Registration" outlined dense v-model="jobForm.carRegistration"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="py-1">
                        <v-combobox
                            label="Car Province"
                            :items="provinces"
                            v-model="jobForm.carRegistrationProvince"
                            dense
                            outlined
                        ></v-combobox>
                    </v-col>
                    <v-col cols="12" md="3" class="py-1">
                        <v-select
                            :items="responsibilityList"
                            label="Responsibility"
                            item-text="responseName"
                            item-value="id"
                            v-model="responseSelected"
                            dense
                            outlined
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3" lg="2" class="py-1">
                        <v-menu v-model="dateModal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="computedJobDateFormatted" :disabled="orderTypeSelected === 'sameday'" label="Date" hint="DD/MM/YYYY" persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense></v-text-field>
                            </template>
                            <v-date-picker v-model="jobDate" :disabled="orderTypeSelected === 'sameday'" no-title @input="dateModal = false"></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="3" lg="2" class="py-1">
                        <v-menu
                            ref="timeModal"
                            v-model="timePickerMenu"
                            :disabled="orderTypeSelected === 'sameday'"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="jobTime"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="jobTime"
                                :disabled="orderTypeSelected === 'sameday'"
                                label="Time"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                class="pt-0"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                            v-if="timePickerMenu"
                            v-model="jobTime"
                            :disabled="orderTypeSelected === 'sameday'"
                            full-width
                            format="24hr"
                            @click:minute="$refs.timeModal.save(jobTime)"
                            ></v-time-picker>
                        </v-menu>
                        <!-- <v-text-field label="Time" type="time" outlined dense v-model="jobTime"></v-text-field> -->
                    </v-col>
                    <v-col cols="12" md="6" lg="2" class="py-1">
                        <v-select
                            :items="orderType"
                            label="Order Type"
                            v-model="orderTypeSelected"
                            :disabled="!$can('all','orderType')"
                            dense
                            outlined
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="12" lg="6" class="py-1">
                        <v-text-field label="Description" outlined  dense v-model="jobForm.description"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <template v-for="item in filteredImages">
                        <v-col cols="12" md="6" :key="item.tmp_id">
                            <ImageInput v-if="item" :id="item.tmp_id" :detail="item" v-model="item.img">
                                <div slot="activator">
                                    <v-avatar size="150px" v-ripple v-if="!item.img.hasOwnProperty('imageURL')" class="grey lighten-3 mb-3">
                                        <span>{{item.label}}</span>
                                    </v-avatar>
                                    <v-avatar size="150px" v-ripple v-else class="mb-3">
                                        <img :src="item.img.imageURL" alt="avatar">
                                    </v-avatar>
                                    <div class="text-center text-h6 ">
                                        {{ item.label }}
                                    </div>
                                </div>
                                <div slot="description">
                                    <v-textarea filled readonly label="รายละเอียด" v-model="item.description" no-resize rows="3" class="pa-0 ma-0 description"></v-textarea>
                                </div>
                            </ImageInput>
                        </v-col>
                    </template>
                </v-row>

                <v-row justify="center" class="my-4">
                    <v-col cols="12" lg="4" class="text-center text-md-right px-0 px-md-2 py-2 py-md-0">
                        <v-btn outlined @click="launchMainImage()" color="secondary">อัพโหลดรูปภาพหลัก</v-btn>
                        <input type="file" ref="mainImg" id="vue-file-upload-input" @change="addMainImage" multiple hidden />
                    </v-col>
                    <v-col cols="12" lg="4" class="text-center text-md-left px-0 px-md-2 py-2 py-md-0">
                        <!-- <v-btn outlined @click="addOptionTemp()" color="secondary">อัพโหลดเพิ่มเติม</v-btn> -->
                        <v-btn outlined @click="launchMultipleImage()" color="secondary">อัพโหลดเพิ่มเติม</v-btn>
                        <input type="file" ref="multipleImg" id="vue-file-upload-input" @change="addImage" multiple hidden />
                    </v-col>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <v-row>
                    <v-col cols="12">
                        <div class="text-h4 text-decoration-underline">Video</div>
                    </v-col>
                </v-row>
                <v-row>
                    <input
                        id="video_upload"
                        name="video"
                        v-on:change="handleFileChange()"
                        accept="video/mp4,video/x-m4v,video/*"
                        ref="file_video"
                        placeholder=""
                        type="file"
                        style="display:none">
                    <template v-if="videos.video_url">
                        <v-col cols="12" md="4" offset-md="4">
                                <video width='100%' controls :src='videos.video_url'></video>
                        </v-col>
                        <v-col cols="12" md="4" offset-md="4" class="py-0">
                            <v-btn outlined color="red" block @click="resetVideo()">Remove Video</v-btn>
                        </v-col>
                    </template>
                    <template v-else>
                        <v-col md="4" offset-md="4" class="video-upload-container">
                            <v-btn class="upload-btn" block color="secondary" @click="launchPicker()" outlined>
                               Select Video <v-icon dark> mdi-plus </v-icon>
                            </v-btn>
                        </v-col>
                    </template>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <v-row justify="center">
                    <v-col cols="12" md="3" class="text-center" :order="$vuetify.breakpoint.smAndDown ? '12' : '0'" >
                        <v-btn to="/job" block color="light"><v-icon dark> mdi-chevron-left </v-icon> Back</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="6" md="3" class="text-center">
                        <v-btn block outlined color="secondary" @click="onSubmitForm('draft')">Draft <v-icon dark> mdi-content-save-edit-outline</v-icon></v-btn>
                    </v-col>
                    <v-col cols="6" md="3" class="text-center">
                        <v-btn block color="primary" @click="onSubmitForm('open')" :disabled="!valid">Open Job</v-btn>
                    </v-col>
                    
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ImageInput from "@/components/base/ImageInput.vue";

export default {
    components: {
        ImageInput
    },
    data() {
        return {
            valid: false,
            dateTimeZone: '',
            reponsibilityValue: {},
            orderTypeValue: '',
            jobDate: new Date().toISOString().substr(0, 10),
            jobTime: null,
            responseSelected: {},
            orderTypeSelected: {},
            saving: false,
            saved: false,
            videos : {
                id: null,
                label: "วีดีโอเพิ่ม",
                description: "-",
                media: null,
                video_url: null,
                position: 'extra',
                section: 'open',
                status: 'active',
                type: 'video'
            },
            file: '',
            dateModal: false,
            dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
            timePickerMenu: false
        };
    },
    watch: {
        jobDate(value) {
            this.dateTimeZone = `${value}T${this.jobTime}+07:00`
            this.jobForm.jobDate = this.dateTimeZone
        },
        jobTime(value) {
            this.dateTimeZone = `${this.jobDate}T${value}+07:00`
            this.jobForm.jobDate = this.dateTimeZone
        },
        responseSelected(value) {

            if (typeof value === 'object' && value !== null){
                this.reponsibilityValue = value.id
            }else{
                this.reponsibilityValue = value
            }
            
            this.jobForm.jobResponsibility = this.reponsibilityValue
        },
        orderTypeSelected(value) {
            this.orderTypeValue = (!this.$can('all','orderType')) ? "sameday" : value
            this.jobForm.orderType = this.orderTypeValue
        },
        mediaDetail: {
            handler(val){
                let status = val.map(item =>{
                    if(item.position == 'extra' || item.type == 'video'){
                        return true;
                    }else{
                        return Object.keys(item.img).length !== 0 ? true : false;
                    }
                });
                this.valid = Object.keys(status).every((k) => status[k]) ? true : false;
            },
            deep: true
        },
        jobForm: {
            handler(val){
                this.valid = (val.jobID) ? true : false;
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters({
            createdBy: 'job/GETTER_CREATED_BY',
            updatedBy: 'job/GETTER_UPDATED_BY',
            orderType: 'job/GETTER_ORDER_TYPE',
            responsibilityList: 'job/GETTER_REPONSIBILITY',
            mediaDetail: 'job/GETTER_JOB_MEDIA',
            jobForm: 'job/GETTER_JOB_FORM',
            filteredImages: 'job/GETTER_MEDIA_IMG',
            provinces: 'job/GETTER_PROVINCE'
        }),
        computedJobDateFormatted() {
            return this.formatDate(this.jobDate);
        },
    },
    async created() {
        await this.getProvince();
        await this.clearJob();
        await this.getResponsibility();
        await this.setDateTime();
        await this.setDefaultSelected();
        await this.modifyJobHeaderData();
    },
    methods: {
        ...mapActions({
            getResponsibility: 'job/GET_RESPONSIBILITY',
            createJob: 'job/CREATE_JOB',
            addOptionTemp: 'job/ADD_OPTION_TMP',
            removeOptionTemp: 'job/REMOVE_OPTIONAL_MEDIA',
            appendVideo: 'job/ADD_VIDEO',
            removeVideoOpen: 'job/REMOVE_VIDEO_OPEN_MEDIA',
            clearJobForm: 'job/CLEAR_JOB_FORM',
            getProvince: 'job/GET_PROVINCE',
            updateOrderType: 'job/UPDATE_ORDER_TYPE',
            addMultiImage: 'job/ADD_MULTI_IMG',
            alertMessage: 'global/ADD_ALERT',
            addMainImages: 'job/ADD_MAIN_IMG',
            optimizeImage: 'global/OPTIMIZE_IMAGE',
            convertUrlToFile: 'global/CONVERT_URL_TO_FILE',
        }),
        clearJob() {
            let clearForm = {
                number: "",
                jobID: "",
                carRegistration: "",
                carRegistrationProvince: "กรุงเทพมหานคร",
                jobDate: "",
                jobResponsibility: "",
                orderType: "",
                description: "",
                status: "",
                detail: [],
                complaint: [],
                responsibilityName: "",
                created_by: "",
                updated_by: ""
            }

            let clearMedia = [
                {
                    id: 1,
                    tmp_id: 1,
                    label: "ด้านหน้ารถ",
                    description: "-",
                    img: {},
                    position: 'front',
                    section: 'open',
                    status: 'active',
                    type: 'image'
                },
                {
                    id: 2,
                    tmp_id: 2,
                    label: "ด้านหลังรถ",
                    description: "-",
                    img: {},
                    position: 'back',
                    section: 'open',
                    status: 'active',
                    type: 'image'
                },
                {
                    id: 3,
                    tmp_id: 3,
                    label: "ด้านซ้ายรถ",
                    description: "-",
                    img: {},
                    position: 'left',
                    section: 'open',
                    status: 'active',
                    type: 'image'
                },
                {
                    id: 4,
                    tmp_id: 4,
                    label: "ด้านขวารถ",
                    description: "-",
                    img: {},
                    position: 'right',
                    section: 'open',
                    status: 'active',
                    type: 'image'
                },
                {
                    id: 5,
                    tmp_id: 5,
                    label: "เลขไมล์",
                    description: "-",
                    img: {},
                    position: 'mileage',
                    section: 'open',
                    status: 'active',
                    type: 'image'
                },
                {
                    id: 6,
                    tmp_id: 6,
                    label: "ยางอะไหล่/อุปกรณ์เสริม",
                    description: "-",
                    img: {},
                    position: 'spareTire',
                    section: 'open',
                    status: 'active',
                    type: 'image'
                }
            ]

            this.clearJobForm({ form: clearForm, media: clearMedia })
        },
        setDateTime() {
            const d = new Date();
            let options = {  
                hour12: false, 
                hour: "2-digit", 
                minute: "2-digit"
                // second: "2-digit"
            };  
            
            this.jobDate = d.toISOString().substr(0, 10)
            this.jobTime = d.toLocaleTimeString('th-TH', options);
        },
        setDefaultSelected() {
            this.responseSelected = this.responsibilityList[0]
            this.orderTypeSelected = this.orderType[0]
        },
        modifyJobHeaderData() {
            this.jobForm.jobDate = this.dateTimeZone
            this.jobForm.created_by = this.createdBy
            this.jobForm.updated_by = this.updatedBy
            this.jobForm.jobResponsibility = this.reponsibilityValue
            this.jobForm.orderType = this.orderTypeValue
        },
        launchPicker() {
            this.$refs.file_video.click();
        },
        resetVideo(){
            this.$refs.file_video.value = null;
            this.videos.video_url = null;
            this.file = null;
            this.removeVideoOpen();
        },
        handleFileChange() {
            this.file = this.$refs.file_video.files[0];
            var tmppath = URL.createObjectURL(this.file);
            this.videos.video_url = tmppath;
            this.appendVideo(this.file)
        },
        onSubmitForm(status) {
            const { id } = JSON.parse(localStorage.getItem('user'));
            let formData = new FormData();

            const formDataResult = this.mediaDetail.map(({ img, ...keepAttrs}, index) => {
                if(keepAttrs.media) {
                    formData.append(`files.detail.${index}.media`, keepAttrs.media, keepAttrs.media.name);
                }
                delete keepAttrs.media;
                return keepAttrs
            })
            this.jobForm.jobUpdateBy = id
            this.jobForm.detail = formDataResult
            this.jobForm.status = status
            this.jobForm.complaint = this.complaintList

            formData.append("data", JSON.stringify(this.jobForm));
            this.createJob(formData);
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        async addMainImage(e) {
            const tmpFiles = e.target.files
            if (tmpFiles.length === 0) {
                return false;
            }

            if (tmpFiles.length > 6) {
                this.alertMessage({ message: `Maximum 6 images for upload to main image.`, color: "warning" })
                return false;
            } else {
                for(let indexFile in tmpFiles){
                    if(indexFile != 'length' && indexFile != 'item'){
                        // const file = tmpFiles[indexFile]
                        let file = null
                        await this.optimizeImage({file:tmpFiles[indexFile]}).then(async (res) =>{
                            await this.convertUrlToFile({url:res, name:tmpFiles[indexFile].name, type:tmpFiles[indexFile].type}).then((image)=>{
                                // console.log(image)
                                // console.log((tmpFiles[indexFile].size - image.size) / tmpFiles[indexFile].size *100)
                                file = image;
                            })
                        })
                        let imageURL = URL.createObjectURL(file)
                        let currentIndex = parseInt(indexFile) + 1;
                        await this.addMainImages({ index: currentIndex, fileImages:file, imageURL:imageURL });
                    }   
                }
            }
        },
        launchMainImage(){
            this.$refs.mainImg.click();
        },
        async addImage(e){
            const tmpFiles = e.target.files
            if (tmpFiles.length === 0) {
                return false;
            }

            for(var f in tmpFiles){
                if(f != 'length' && f != 'item'){
                    // const file = tmpFiles[f]
                    let file = null
                    await this.optimizeImage({file:tmpFiles[f]}).then(async (res) =>{
                        await this.convertUrlToFile({url:res, name:tmpFiles[f].name, type:tmpFiles[f].type}).then((image)=>{
                            // console.log(image)
                            // console.log((tmpFiles[f].size - image.size) / tmpFiles[f].size *100)
                            file = image;
                        })
                    })
                    let imageURL = URL.createObjectURL(file)
                    await this.addMultiImage({fileImages:file, imageURL:imageURL});
                }   
            }
        },
        launchMultipleImage(){
            this.$refs.multipleImg.click();
        }
    }
};
</script>

<style lang="scss" scoped>
.video-upload-container {
    height: 150px;

    button.upload-btn {
        height: 100%;
    }
}
</style>
