<template>
<div>
    <div class="title-header">
        <h1>{{ jobDetailAll.number }}</h1>
    </div>
    <v-container id="jobsTable" tag="section">
        <v-card class="card-container" elevation="0">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="3" class="py-1">
                        <v-text-field label="Job ID" outlined dense filled readonly v-model="jobDetailAll.jobID" :disabled="!$can('edit', 'job', jobDetailAll.status) || $can('editSome', 'job', jobDetailAll.status)"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="py-1">
                        <v-text-field label="Car Registration" outlined dense v-model="jobDetailAll.carRegistration" :disabled="!$can('edit', 'job', jobDetailAll.status)|| $can('editSome', 'job', jobDetailAll.status)"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="py-1">
                         <v-combobox
                            label="Car Province"
                            :items="provinces"
                            v-model="jobDetailAll.carRegistrationProvince"
                            dense
                            outlined
                            :disabled="!$can('edit', 'job', jobDetailAll.status) || $can('editSome', 'job', jobDetailAll.status)"
                        ></v-combobox>
                    </v-col>
                    <v-col cols="12" md="3" class="py-1">
                        <v-select
                            :items="responsibilityList"
                            label="Responsibility"
                            item-text="responseName"
                            item-value="id"
                            v-model="responseSelected"
                            dense
                            outlined
                            :disabled="!$can('edit', 'job', jobDetailAll.status)"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3" lg="2" class="py-1">
                        <v-menu v-model="dateModal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ attrs }">
                                <v-text-field :disabled="!$can('edit', 'job', jobDetailAll.status) || $can('editSome', 'job', jobDetailAll.status)" v-model="computedJobDateFormatted" label="Date" hint="DD/MM/YYYY" persistent-hint prepend-icon="mdi-calendar" readonly v-bind="attrs" dense></v-text-field>
                            </template>
                            <v-date-picker :disabled="!$can('edit', 'job', jobDetailAll.status)|| $can('editSome', 'job', jobDetailAll.status)" v-model="jobDate" no-title @input="dateModal = false"></v-date-picker>
                        </v-menu>
                         <!-- <v-text-field label="Date" type="date" outlined dense v-model="jobDate"></v-text-field> -->
                    </v-col>
                    <v-col cols="12" md="3" lg="2"  class="py-1">
                        <v-menu
                            ref="timeModal"
                            v-model="timePickerMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="jobTime"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            :disabled="!$can('edit', 'job', jobDetailAll.status)|| $can('editSome', 'job', jobDetailAll.status)"
                        >
                            <template v-slot:activator="{ attrs }">
                            <v-text-field
                                v-model="jobTime"
                                :disabled="!$can('edit', 'job', jobDetailAll.status) || $can('editSome', 'job', jobDetailAll.status)"
                                label="Time"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                class="pt-0"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                                v-if="timePickerMenu"
                                :disabled="!$can('edit', 'job', jobDetailAll.status) || $can('editSome', 'job', jobDetailAll.status)"
                                v-model="jobTime"
                                full-width
                                format="24hr"
                                @click:minute="$refs.timeModal.save(jobTime)"
                            ></v-time-picker>
                        </v-menu>
                        <!-- <v-text-field label="Time" type="time" outlined dense v-model="jobTime"></v-text-field> -->
                    </v-col>
                    <v-col cols="12" md="6" lg="2" class="py-1">
                         <v-select
                            :items="orderType"
                            label="Order Type"
                            v-model="orderTypeSelected"
                            :disabled="!$can('edit', 'job', jobDetailAll.status) || $can('editSome', 'job', jobDetailAll.status)"
                            dense
                            outlined
                        ></v-select> 
                            <!-- <v-text-field label="Order Type" filled readonly outlined dense v-model="jobDetailAll.orderType"></v-text-field> -->
                    </v-col>
                    <v-col cols="12" md="12" lg="6" class="py-1">
                        <v-text-field v-if="$can('comment', 'job', jobDetailAll.status)" label="Description" outlined dense v-model="jobDetailAll.description"></v-text-field>
                        <v-text-field v-else :disabled="!$can('edit', 'job', jobDetailAll.status)" label="Description" outlined dense v-model="jobDetailAll.description"></v-text-field>
                    </v-col>
                </v-row>

                <v-expansion-panels flat accordion>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="text-h4">Opened Job</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <template v-for="item in filterDetailTypeSection(mediaDetail, 'image', 'open')">
                                    <v-col v-if="item.type !== 'video'" cols="12" md="6" :key="item.tmp_id">
                                        <ImageInput v-if="item" :id="item.tmp_id" :detail="item" v-model="item.img" :fieldDisable="true" :docNo="jobDetailAll.number">
                                            <div slot="activator">
                                                <v-avatar size="150px" v-ripple v-if="item.img.hasOwnProperty('imageURL') ? item.img.imageURL == null ? true : false : false" class="grey lighten-3 mb-3">
                                                    <span>{{item.label}}</span>
                                                </v-avatar>
                                                <v-avatar size="150px" v-ripple v-else-if="!item.img.hasOwnProperty('imageURL')" class="grey lighten-3 mb-3">
                                                    <span>{{item.label}}</span>
                                                </v-avatar>
                                                <v-avatar size="150px" v-ripple v-else class="mb-3">
                                                    <img :src="item.img.imageURL" alt="avatar">
                                                </v-avatar>
                                                <div class="text-center text-h6 ">
                                                    {{ item.label }}
                                                </div>
                                            </div>
                                            <div slot="description">
                                                <v-textarea filled readonly label="รายละเอียด" v-model="item.description" no-resize rows="3" class="pa-0 ma-0 description"></v-textarea>
                                            </div>
                                        </ImageInput>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-divider class="my-4"></v-divider>

                <v-expansion-panels flat accordion>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="text-h4">Closing Job</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <template v-for="item in filterDetailTypeSection(mediaDetail, 'image', 'close')">
                                    <v-col v-if="item.type !== 'video'" cols="12" md="6" :key="item.tmp_id">
                                        <ImageInput v-if="item" :id="item.tmp_id" :detail="item" v-model="item.img" :fieldDisable="!$can('edit', 'job', jobDetailAll.status)">
                                            <div slot="activator">
                                                <v-avatar size="150px" v-ripple v-if="item.img.hasOwnProperty('imageURL') ? item.img.imageURL == null ? true : false : false" class="grey lighten-3 mb-3">
                                                    <span>{{item.label}}</span>
                                                </v-avatar>
                                                <v-avatar size="150px" v-ripple v-else-if="!item.img.hasOwnProperty('imageURL')" class="grey lighten-3 mb-3">
                                                    <span>{{item.label}}</span>
                                                </v-avatar>
                                                <v-avatar size="150px" v-ripple v-else class="mb-3">
                                                    <img :src="item.img.imageURL" alt="avatar">
                                                </v-avatar>
                                                <div class="text-center text-h6 ">
                                                    {{ item.label }}
                                                </div>
                                            </div>
                                            <div slot="description">
                                                <v-textarea filled readonly label="รายละเอียด" v-model="item.description" no-resize rows="3" class="pa-0 ma-0 description"></v-textarea>
                                            </div>
                                        </ImageInput>
                                    </v-col>
                                </template>
                            </v-row>
                            <v-row v-if="$can('edit', 'job', jobDetailAll.status)" justify="center" class="my-4">
                                <v-col cols="12" lg="4" class="text-center text-md-right px-0 px-md-2 py-2 py-md-0">
                                    <v-btn outlined @click="launchMainImage()" color="secondary">อัพโหลดรูปภาพหลัก</v-btn>
                                    <input type="file" ref="mainImg" id="vue-file-upload-input" @change="addMainImage" multiple hidden />
                                </v-col>
                                <v-col cols="12" lg="4" class="text-center text-md-left px-0 px-md-2 py-2 py-md-0">
                                    <!-- <v-btn outlined @click="addOptionTemp()" color="secondary">อัพโหลดเพิ่มเติม</v-btn> -->
                                    <v-btn outlined @click="launchMultipleImage()" color="secondary">อัพโหลดเพิ่มเติม</v-btn>
                                    <input type="file" ref="multipleImg" id="vue-file-upload-input" @change="addImage" multiple hidden />
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-divider class="my-4"></v-divider>
                <v-row>
                    <v-col cols="12">
                        <div class="text-h4 text-decoration-underline">Video</div>
                    </v-col>
                </v-row>
                <v-row>
                    <input
                        id="video_upload"
                        name="video"
                        v-on:change="handleFileChange()"
                        accept="video/mp4,video/x-m4v,video/*"
                        ref="file_video"
                        placeholder=""
                        type="file"
                        style="display:none">
                    <template v-if="videos.video_url">
                        <v-col cols="12" md="4" offset-md="4">
                                <video width='100%' controls :src='videos.video_url'></video>
                        </v-col>
                        <v-col cols="12" md="4" offset-md="4" class="py-0">
                            <v-btn v-if="$can('edit', 'job', jobDetailAll.status)" outlined color="red" block @click="resetVideo()">Remove Video</v-btn>
                        </v-col>
                    </template>
                    <template v-else>
                        <v-col v-if="$can('edit', 'job', jobDetailAll.status)" md="4" offset-md="4" class="video-upload-container">
                            <v-btn class="upload-btn" block color="secondary" @click="launchPicker()" outlined>
                               Select Video <v-icon dark> mdi-plus </v-icon>
                            </v-btn>
                        </v-col>
                        <template v-else>
                            <v-col offset="4">
                                <v-card class="py-6">
                                    <v-card-text class="text-center">
                                        <strong>No Video</strong> <v-icon>mdi-file-settings-outline</v-icon>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="4"></v-col>
                        </template>
                    </template>
                </v-row>
                <v-divider class="my-4"></v-divider>
                <v-row v-if="$can('see', 'complaint', jobDetailAll.status)">
                    <v-col cols="12">
                        <div class="text-h4 text-decoration-underline">Complaint</div>
                    </v-col>
                </v-row>
                <v-row v-if="complaintList.length === 0">
                    <v-col offset="4">
                        <v-card class="py-6">
                            <v-card-text class="text-center">
                                <strong>No Complaint</strong> <v-icon>mdi-file-settings-outline</v-icon>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="4"></v-col>
                </v-row>
                <v-row v-if="$can('see', 'complaint', jobDetailAll.status)">
                    <template v-for="comp in complaintList" >
                        <add-complaint-modal :key="comp.tmp_id" :complaint="comp" :fieldDisable="jobDetailAll.status=='open'|| jobDetailAll.status=='reject'|| !$can('edit', 'complaint', jobDetailAll.status)">
                            <!-- <div slot="removeItemComplaint">
                                <v-btn color="red" icon @click="removeComplaint(comp.tmp_id)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </div> -->
                            <div slot="activator">
                                <v-card>
                                    <v-card-title>
                                        <span class="headline">{{comp.topic}}</span>
                                        <v-spacer></v-spacer>
                                        <!-- <v-btn icon>
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn> -->
                                    </v-card-title>
                                    <v-card-text class="text-left">
                                        <p>{{comp.content}}</p>
                                    </v-card-text>
                                </v-card>
                            </div>
                        </add-complaint-modal>
                    </template>
                </v-row>
                <v-row v-if="$can('add', 'complaint', jobDetailAll.status)" justify="center" class="my-4">
                    <v-col cols="12" lg="4" class="text-center pa-0">
                        <v-btn outlined @click="addComplaintTemp()" color="secondary">Add Complaint</v-btn>
                    </v-col>
                </v-row>
                <v-divider v-if="$can('see', 'complaint', jobDetailAll.status)" class="my-4"></v-divider>
                <!-- <v-row>
                    <v-col cols="12">
                        <div class="text-h4 text-decoration-underline">Signature</div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col md="6" v-if="!signature">
                        <VueSignaturePad
                            :height="this.signatureContainerHeight"
                            ref="signaturePad"
                            :customStyle="this.signatureStyle"
                        />
                    </v-col>
                    <v-col class="d-flex flex-column justify-space-between align-center" md="6" v-else>
                         <v-img max-height="250" max-width="350" :src="signature"></v-img>
                    </v-col>
                </v-row>
                <v-row justify="center" v-if="!signature">
                    <v-col cols="6" md="3" class="text-center">
                        <v-btn @click="clearSignature()" block color="light">
                            Reset
                        </v-btn>
                    </v-col>
                    <v-col cols="6" md="3" class="text-center">
                        <v-btn @click="confirmSignature()" block color="primary">
                            Sign
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row justify="center" v-else>
                    <v-col v-if="$can('edit', 'job', jobDetailAll.status)" cols="6" md="3" class="text-center">
                        <v-btn @click="resetSignature()" block outlined color="red">
                            Resign
                            <v-icon right dark> mdi-refresh </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                
                <v-divider class="my-4"></v-divider> -->

                <v-row justify="center">
                    <v-col cols="12" md="3" class="text-center" :order="$vuetify.breakpoint.smAndDown ? '12' : '0'">
                        <v-btn to="/job" block color="light"><v-icon dark> mdi-chevron-left </v-icon> Back</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col v-if="$can('save', 'job', jobDetailAll.status)" cols="6" md="3" class="text-center">
                        <v-btn block outlined color="secondary" @click="onSubmitForm()">Save <v-icon dark> mdi-content-save-edit-outline</v-icon></v-btn>
                    </v-col>
                    <v-col cols="6" md="3" v-if="$can('cancel', 'job', jobDetailAll.status)" class="text-center" :order="$vuetify.breakpoint.smAndDown ? '10' : '0'">
                        <v-btn block color="light" @click="onSubmitForm('cancel')">Cancel Job</v-btn>
                    </v-col>
                    <v-col cols="6" md="3" v-if="$can('reject', 'job', jobDetailAll.status)" class="text-center" :order="$vuetify.breakpoint.smAndDown ? '11' : '0'">
                        <v-btn block color="red white--text" @click="onSubmitForm('reject')">Reject</v-btn>
                    </v-col>
                    <v-col cols="6" md="3" v-if="$can('approve', 'job', jobDetailAll.status)" class="text-center">
                        <v-btn block color="primary" @click="onSubmitForm('requested')" :disabled="!valid">{{ approveLabel }}</v-btn>
                    </v-col>
                    <v-col cols="12" md="3" v-if="$can('close', 'job', jobDetailAll.status)" class="text-center" >
                        <v-btn block class="white--text" color="green" @click="onSubmitForm('closed')">Close Job<v-icon dark> mdi-check</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-container>
</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ImageInput from "@/components/base/ImageInput.vue";
import AddComplaintModal from "./AddComplaint.vue"

export default {
    components: {
        AddComplaintModal,
        ImageInput
    },
    data() {
        return {
            approveLabel: window.innerWidth > 768 ? 'Request for Approval' : 'Request' , //คำแรกยาวทะลุกรอบ Mobile
            valid: false,
            dateTimeZone: '',
            reponsibilityValue: {},
            orderTypeValue: '',
            jobDate: new Date().toISOString().substr(0, 10),
            jobTime: null,
            responseSelected: {},
            orderTypeSelected: {},
            saving: false,
            saved: false,
            videos : {
                id: null,
                label: "วีดีโอเพิ่ม",
                description: "-",
                media: null,
                video_url: null,
                position: 'extra',
                section: 'open',
                status: 'active',
                type: 'video'
            },
            file: '',
            dateModal: false,
            dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
            timePickerMenu: false,
            signatureContainerHeight: '150px',
            signatureStyle: {background:"#f5f5f5"}
        };
    },
    watch: {
        jobDate(value) {
            this.dateTimeZone = `${value}T${this.jobTime}+07:00`
            this.jobDetailAll.jobDate = this.dateTimeZone
        },
        jobTime(value) {
            this.dateTimeZone = `${this.jobDate}T${value}+07:00`
            this.jobDetailAll.jobDate = this.dateTimeZone
        },
        responseSelected(value) {
            if (typeof value === 'object' && value !== null){
                this.reponsibilityValue = value.id
            }else{
                this.reponsibilityValue = value
            }

            this.jobDetailAll.jobResponsibility = this.reponsibilityValue
        },
        orderTypeSelected(value) {
            this.orderTypeValue = value
            this.jobDetailAll.orderType = this.orderTypeValue
        },
        mediaDetail: {
            handler(val){
                const { status: jobStatus } = this.jobDetailAll;
                let status = val.map(item =>{
                    if(item.position == 'extra' || item.type == 'video'){
                        return true;
                    } else if(item.position === "signature") {
                        return true;
                    } else {
                        return item.img.imageURL ? true : false;
                    }
                });

                // const hadSignature = val.some(item => item.position === "signature");
                // if(!hadSignature) status.push(false);
                
                if(jobStatus.includes("requested") || jobStatus.includes("pending") || jobStatus.includes("closed")) {
                    status.push(false)
                    this.approveLabel = jobStatus;
                } else {
                    status.push(true);
                    this.approveLabel = window.innerWidth > 768 ? 'Request for Approval' : 'Request';
                }

                this.valid = Object.keys(status).every((k) => status[k]) ? true : false;
                
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters({
            createdBy: 'job/GETTER_CREATED_BY',
            updatedBy: 'job/GETTER_UPDATED_BY',
            orderType: 'job/GETTER_ORDER_TYPE',
            responsibilityList: 'job/GETTER_REPONSIBILITY',
            complaintList: 'job/GETTER_JOB_COMPLAINT',
            jobDetailAll: 'job/GETTER_JOB_FORM',
            mediaDetail: 'job/GETTER_JOB_MEDIA',
            provinces: 'job/GETTER_PROVINCE',
            signature: 'job/GETTER_SIGNATURE'
        }),
        computedJobDateFormatted() {
            return this.formatDate(this.jobDate);
        },
    },
    async created() {
        await this.getProvince();
        await this.getJobDetail({ id: this.$route.params.id, fromEdit: true }); 
        await this.getResponsibility();
        await this.setDateTime();
        await this.setDefaultSelected();
        await this.setDefaultVideo();
        await this.modifyJobHeaderData();
    },
    methods: {
        ...mapActions({
            getResponsibility: 'job/GET_RESPONSIBILITY',
            addOptionTemp: 'job/ADD_OPTION_TMP',
            removeOptionTemp: 'job/REMOVE_OPTIONAL_MEDIA',
            addComplaintTemp: 'job/ADD_COMPLAINT_TMP',
            removeComplaint: 'job/REMOVE_COMPLAINT',
            initialComplaint: 'job/INITIAL_COMPLAINT',
            appendVideo: 'job/ADD_VIDEO',
            appendSignature: 'job/ADD_SIGNATURE',
            removeVideoOpen: 'job/REMOVE_VIDEO_OPEN_MEDIA',
            removeSignature: 'job/REMOVE_SIGNATURE',
            getJobDetail: 'job/GET_JOB_DETAIL',
            putJob: 'job/PUT_JOB',
            getProvince: 'job/GET_PROVINCE',
            addMultiImage: 'job/ADD_MULTI_IMG',
            alertMessage: 'global/ADD_ALERT',
            addMainImages: 'job/ADD_MAIN_IMG',
            optimizeImage: 'global/OPTIMIZE_IMAGE',
            convertUrlToFile: 'global/CONVERT_URL_TO_FILE',
        }),
        setDateTime() {
            const d = new Date(this.jobDetailAll.jobDate);
            let options = {  
                hour12: false, 
                hour: "2-digit", 
                minute: "2-digit"
                // second: "2-digit"
            };  
            
            this.jobDate = d.toISOString().substr(0, 10)
            this.jobTime = d.toLocaleTimeString('th-TH', options);
        },
        setDefaultSelected() {
            const { id, firstname, lastname } = this.jobDetailAll.jobResponsibility
            const tmpRes = { id: `${id}`, responseName: `${firstname} ${lastname}` }
            this.responseSelected = tmpRes
            this.orderTypeSelected = this.jobDetailAll.orderType
        },
        modifyJobHeaderData() {
            this.initialComplaint(this.jobDetailAll.complaint);
            this.jobDetailAll.jobDate = this.dateTimeZone
            this.jobDetailAll.created_by = this.createdBy
            this.jobDetailAll.updated_by = this.updatedBy
            this.jobDetailAll.jobResponsibility = this.reponsibilityValue
            this.jobDetailAll.orderType = this.orderTypeValue
        },
        launchPicker() {
            this.$refs.file_video.click();
        },
        filterDetailTypeSection(media, type, section) {
            return media.filter(item => item.type === type && item.section === section && item.position !== 'signature')
        },
        setDefaultVideo() {
            let videoObj = { ...this.videos }
            let video = this.mediaDetail.find(item => item.type === "video")

            if(video) {
                videoObj.id = video.id
                videoObj.label = video.label
                videoObj.description = video.description
                videoObj.media = video.media
                videoObj.video_url = video.img.imageURL
                videoObj.position = video.position
                videoObj.section = video.section
                videoObj.status = video.status
                videoObj.type = video.type

                this.videos = { ...videoObj }
            }
        },
        resetVideo(){
            this.$refs.file_video.value = null;
            this.videos.video_url = null;
            this.file = null;
            this.removeVideoOpen();
        },
        handleFileChange() {
            this.file = this.$refs.file_video.files[0];
            var tmppath = URL.createObjectURL(this.file);
            this.videos.video_url = tmppath;
            this.appendVideo(this.file)
        },
        onSubmitForm(status) {
            const { id } = JSON.parse(localStorage.getItem('user'));
            let formData = new FormData();
            
            const formDataResult = this.mediaDetail.map(({ img, ...keepAttrs}, index) => {
                if(keepAttrs.media && !keepAttrs.mediaId) {
                    formData.append(`files.detail.${index}.media`, keepAttrs.media, keepAttrs.media.name);
                    keepAttrs.media = null;
                }else{
                    delete keepAttrs.media;
                }
                return keepAttrs
            })
            
            if (typeof this.responseSelected === 'object' && this.responseSelected !== null){
                this.jobDetailAll.jobResponsibility = this.responseSelected.id;
            }else{
                this.jobDetailAll.jobResponsibility = this.responseSelected;
            }
            this.jobDetailAll.jobUpdateBy = id
            this.jobDetailAll.detail = formDataResult
            this.jobDetailAll.status = status ? status : this.jobDetailAll.status
            this.jobDetailAll.complaint = this.complaintList
            formData.append("data", JSON.stringify(this.jobDetailAll));
            this.putJob({ id: this.$route.params.id, data: formData });
        },
        formatDate(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        clearSignature() {
            this.$refs.signaturePad ? this.$refs.signaturePad.clearSignature() : null;
        },
        resetSignature() {
            this.$refs.signaturePad ? this.$refs.signaturePad.clearSignature() : null;
            this.removeSignature();
        },
        confirmSignature(){ //ทดสอบ convert signature base64 to blob
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            if(!isEmpty){
                var block = data.split(";");
                var contentType = block[0].split(":")[1];// In this case "image/gif"
                // get the real base64 content of the file
                var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
                // Convert it to a blob to upload
                var blob = this.b64toBlob(realData, contentType);
                let file = new File([blob], "signature", {type: contentType});
                this.appendSignature({fileSignature:file, base64:data});
                this.$refs.signaturePad.lockSignaturePad();
            }
        },
        b64toBlob(b64Data, contentType, sliceSize) {
            contentType = contentType || '';
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, {type: contentType});
            return blob;
        },
        async addMainImage(e) {
            const tmpFiles = e.target.files
            if (tmpFiles.length === 0) {
                return false;
            }

            if (tmpFiles.length > 6) {
                this.alertMessage({ message: `Maximum 6 images for upload to main image.`, color: "warning" })
                return false;
            } else {
                const { tmp_id } = this.mediaDetail.filter(m => m.section == 'close').shift()
                let currentIndex = 0
                for(let indexFile in tmpFiles){
                    if(indexFile != 'length' && indexFile != 'item'){
                        if(parseInt(indexFile) === 0){
                            currentIndex = parseInt(tmp_id)
                        } else {
                            currentIndex = parseInt(currentIndex) + 1;
                        }
                        // const file = tmpFiles[indexFile]
                        let file = null
                        await this.optimizeImage({file:tmpFiles[indexFile]}).then(async (res) =>{
                            await this.convertUrlToFile({url:res, name:tmpFiles[indexFile].name, type:tmpFiles[indexFile].type}).then((image)=>{
                                // console.log(image)
                                // console.log((tmpFiles[indexFile].size - image.size) / tmpFiles[indexFile].size *100)
                                file = image;
                            })
                        })
                        let imageURL = URL.createObjectURL(file)
                        await this.addMainImages({ index: currentIndex, fileImages:file, imageURL:imageURL, edit:true });
                    }   
                }
            }
        },
        launchMainImage(){
            this.$refs.mainImg.click();
        },
        async addImage(e){
            const tmpFiles = e.target.files
            if (tmpFiles.length === 0) {
                return false;
            }

            for(var f in tmpFiles){
                if(f != 'length' && f != 'item'){
                    // const file = tmpFiles[f]
                    let file = null
                    await this.optimizeImage({file:tmpFiles[f]}).then(async (res) =>{
                        await this.convertUrlToFile({url:res, name:tmpFiles[f].name, type:tmpFiles[f].type}).then((image)=>{
                            // console.log(image)
                            // console.log((tmpFiles[f].size - image.size) / tmpFiles[f].size *100)
                            file = image;
                        })
                    })
                    let imageURL = URL.createObjectURL(file)
                    await this.addMultiImage({fileImages:file, imageURL:imageURL, edit: true});
                }   
            }
        },
        launchMultipleImage(){
            this.$refs.multipleImg.click();
        }
    }
};
</script>

<style lang="scss" scoped>
.video-upload-container {
    height: 150px;

    button.upload-btn {
        height: 100%;
    }
}
</style>
